import { all, takeEvery, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { clearToken, getToken } from '../../helpers/utility';
import actions from './actions';
import { login, getAuth } from './apis';

// const fakeApiCall = true; // auth0 or express JWT

export function* loginRequest (payload)
{
  
  try
  {
    const data = yield call(login, payload.payload);
    const { token, user } = data.data;
    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: { token: token },
    });

    yield put({
      type: actions.ADD_PROFILE,
      payload: { ...user },
    });

  } catch (e)
  {
    yield put({
      type: actions.LOGIN_ERROR,
      payload: "Invalid username or password"
    });
  }
}

export function* loginSuccess ({ payload })
{
  yield localStorage.setItem('id_token', payload.token);
}

export function* loginError ({ payload })
{
  yield console.log(payload)
}

export function* logout() {
  clearToken();
  yield put(push('/'));
}

export function* checkAuthorization ()
{
  const token = getToken();
  if (token) {
    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: { token: token },
      profile: 'Profile',
    });

    const { data } = yield call(getAuth)
    yield put({
      type: actions.ADD_PROFILE,
      payload: { ...data },
    });
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.CHECK_AUTHORIZATION, checkAuthorization),
    yield takeEvery(actions.LOGIN_REQUEST, loginRequest),
    yield takeEvery(actions.LOGIN_SUCCESS, loginSuccess),
    yield takeEvery(actions.LOGIN_ERROR, loginError),
    yield takeEvery(actions.LOGOUT, logout),
  ]);
}
