import actions from "./actions";

const initState = {
  idToken: null,
  user_id: null,
  role_id: null,
  role_name: null,
  firstName: null,
  lastName: null,
  email: null,
  error: null
};

export default function authReducer (state = initState, action)
{
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.payload.token
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case actions.LOGOUT:
      return initState;
    case actions.ADD_PROFILE:
      return {
        ...state,
        avtar: action.payload.avtar,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        phone: action.payload.phone,
        user_id: action.payload?._id,
        role_id: action.payload?.role_id?._id,
        role_name: action.payload?.role_id?.name
      }
    default:
      return state;
  }
}
