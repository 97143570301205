import axios from '../../helpers/axiosInterceptor';

export const getSpecialist = async () => await axios.get(`/api/specialist`);

export const deleteSpecialist = async (id) => await axios.delete(`/api/specialist/${id}`);

export const createSpecialist = async (value) => await axios.post('/api/specialist', value);

export const updateSpecialist = async (id, value) => await axios.put(`/api/specialist/${id}`, value);

export const getDetailsSpecialist = async (id) => await axios.get(`/api/specialist/${id}`);
