const actions = {
  GET_SPECIALITY: 'GET_SPECIALITY',
  SET_SPECIALITY: 'SET_SPECIALITY',
  ADD_SPECIALITY: 'ADD_SPECIALITY',
  ADD_RETURN: 'CREATE_RETURN',
  DELETE_SPECIALITY: 'DELECT_SPECIALITY',
  SET_ERROR: 'SET_ERROR',
  DELETE_RETURN: 'DELETE_RETURN',
  GET_SPECIALITY_BY_ID: 'GET_SPECIALITY_BY_ID',
  SET_SPECIALITY_BY_ID: 'SET_SPECIALITY_BY_ID',
  UPDATE_SPECIALITY: 'UPDATE_SPECIALITY',
  UPDATE_RETURN: 'UPDATE_RETURN',
  getSpecialityById: (id) => ({ type: actions.GET_SPECIALITY_BY_ID, id }),
  getSpeciality: () => ({ type: actions.GET_SPECIALITY }),
  deleteSpeciality: (id) => ({ type: actions.DELETE_SPECIALITY, id }),
  addSpeciality: (payload, history) => ({ type: actions.ADD_SPECIALITY, payload, history }),
  updateSpeciality: (id, payload) => ({ type: actions.UPDATE_SPECIALITY, id, payload })
};
export default actions;
