import { put, takeLatest, call } from 'redux-saga/effects';
// import { push } from 'connected-react-router';
// import { clearToken, getToken } from '../../helpers/utility';
import actions from './actions';
import authActions from '../auth/actions';
import { 
  getSpecialist,
  deleteSpecialist,
  createSpecialist,
  getDetailsSpecialist,
  updateSpecialist
} from './apis';


export function* getSpeciality ()
{
  try
  {
    const data = yield call(getSpecialist);
    yield put({
      type: actions.SET_SPECIALITY,
      data: data.data
    }); 
  } catch (e)
  {
    const { expiredFlag } = e.response.data;
    if ( expiredFlag )
    {
      yield put({
        type: authActions.LOGOUT,
      });
    }
  } 
}

export function* deleteSpeciality (payload)
{
  try
  {
    yield call(deleteSpecialist, payload.id);
    yield put({
      type: actions.DELETE_RETURN,
      error: '',
      id: payload.id
    });
  } catch (e)
  {
    yield put({
      type: actions.DELETE_RETURN,
      error: e
    });
  } 
}

export function* createSpeciality ({payload, history})
{
  try
  {
    yield call(createSpecialist, payload);
    yield put({ type: actions.GET_SPECIALITY });
    history.push('/dashboard/speciality')
  } catch (e)
  {
    yield put({
      type: actions.SET_ERROR,
      error: e.response.data.error
    })
    yield put({
      type: actions.ADD_RETURN,
      error: e
    });
  } 
}

export function* updateSpeciality ({id, payload})
{
  try
  {
    yield call(updateSpecialist, id, payload);
    yield put({ type: actions.GET_SPECIALITY });

  } catch (e)
  {
    yield put({
      type: actions.UPDATE_RETURN,
      error: e
    });
  } 
}


export function* getByIdSpeciality ({ id })
{
  try
  {
    let data = yield call(getDetailsSpecialist, id);
    yield put({
      type: actions.SET_SPECIALITY_BY_ID,
      data: data.data
    }); 
  } catch (e)
  {
    const { expiredFlag } = e.response.data;
    if ( expiredFlag )
    {
      yield put({
        type: authActions.LOGOUT,
      });
    }
  } 
}

export default function* rootSaga() {
  
  yield takeLatest(actions.GET_SPECIALITY, getSpeciality);
  yield takeLatest(actions.DELETE_SPECIALITY, deleteSpeciality);
  yield takeLatest(actions.ADD_SPECIALITY, createSpeciality);
  yield takeLatest(actions.GET_SPECIALITY_BY_ID, getByIdSpeciality);
  yield takeLatest(actions.UPDATE_SPECIALITY, updateSpeciality);
}
