import React from 'react';
import ReactDOM from 'react-dom';
import MaterialAdmin from './materialAdmin';
import * as serviceWorker from './serviceWorker';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';
import { BrowserRouter } from 'react-router-dom'
// if ("serviceWorker" in navigator) {
//     navigator.serviceWorker
//       .register("./firebase-messaging-sw.js")
//       .then(function(registration) {
//         console.log("Registration successful, scope is:", registration.scope);
//       })
//       .catch(function(err) {
//         console.log("Service worker registration failed, error:", err);
//       });
//   }


ReactDOM.render(
    <BrowserRouter>
        <BreadcrumbsProvider>
            <MaterialAdmin />
        </BreadcrumbsProvider>
    </BrowserRouter>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
