import actions from "./actions";

const initState = { data: null, error: null, detail: null };

export default function specialityReducer (state = initState, action)
{
  switch (action.type) {
    case actions.GET_SPECIALITY:
      return { ...state, data: action.data };
    case actions.SET_SPECIALITY:
      return { ...state, data: action.data };
    case actions.SET_SPECIALITY_BY_ID:
      return { detail: action.data };
    case actions.DELETE_RETURN:
      return (action.error) ? ({ ...state }) :
        ({ ...state, data: state.data.filter(item => item._id !== action.id) }) 
    case actions.SET_ERROR:
      return ({ ...state, error: action.error })
    default:
      return state;
  }
}
