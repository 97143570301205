import axios from "axios";
import { getToken } from "./utility";
import { store } from "../redux/store";
import authActions from "../redux/auth/actions";
import config from "../settings";

const PROD = {
  baseUrl: config.baseUrl,
  cdnUrl: config.cdnUrl,
};

const DEV = {
  baseUrl: config.baseUrl,
  cdnUrl: config.cdnUrl,
};

axios.interceptors.request.use(
  (config) => {

    if (process?.env?.NODE_ENV === "production") {
      config.url = config.url.includes("/api/")
        ? PROD.baseUrl + config.url
        : PROD.cdnUrl + config.url;
    } else {
      console.log("config.url", config.url);
      config.url = config.url.includes("/api/")
        ? DEV.baseUrl + config.url
        : DEV.cdnUrl + config.url;
    }
    const token = getToken();
    config.headers = {
      "x-auth-token": token,
      "Content-Type": "application/json; charset=utf-8",
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(error?.response);
    if (
      error?.response?.data?.errors &&
      error?.response?.data?.errors[0].expiredFlag
    ) {
      store.dispatch(authActions.logout());
    } else {
      return Promise.reject(error);
    }
  }
);

export const setToken = (token) => {
  axios.interceptors.request.use(
    (config) => {
      config.headers = {
        "x-auth-token": token,
        "Content-Type": "application/json; charset=utf-8",
      };
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export default axios;
