import React, { Component, lazy, Suspense } from 'react';
import Route from '../../components/utility/customRoute';
import Loader from '../../components/utility/Loader/';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
export const routes = [
  {
    path: '',
    name: 'Home',
    roles: [],
    component: lazy(() => import('../Dashboard')),
  },
  {
    path: 'speciality',
    name: 'speciality',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Speciality/index')),
  },
  {
    path: 'speciality/add',
    name: 'speciality add',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Speciality-add/index')),
  },
  {
    path: 'speciality/details/:id',
    name: 'speciality details',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Speciality-details/index')),
  },
  {
    path: 'speciality/edit/:id',
    name: 'speciality edit',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Speciality-add/index')),
  },
  {
    path: 'languages',
    roles: ['superadmin', 'admin'],
    name: 'Languages',
    component: lazy(() => import('../Language/index')),
  },
  {
    path: 'languages/add',
    roles: ['superadmin', 'admin'],
    name: 'Add Language',
    component: lazy(() => import('../Language-add/index')),
  },
  {
    path: 'languages/details/:id',
    roles: ['superadmin', 'admin'],
    name: 'Details Language',
    component: lazy(() => import('../Language-details/index')),
  },
  {
    path: 'languages/edit/:id',
    roles: ['superadmin', 'admin'],
    name: 'Edit Language',
    component: lazy(() => import('../Language-add/index')),
  },
  {
    path: 'roles',
    name: 'Roles',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Roles/index')),
  },
  {
    path: 'roles/add',
    name: 'Add Roles',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Roles-add/index')),
  },
  {
    path: 'roles/details/:id',
    name: 'Details Roles',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Roles-details/index')),
  },
  {
    path: 'roles/edit/:id',
    name: 'Edit Roles',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Roles-add/index')),
  },
  {
    path: 'notification',
    name: 'Notifications',
    component: lazy(() => import('../Notification/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'notification/add',
    component: lazy(() => import('../Notification-add/index')),
    name: 'Add Notification',
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'notification/details/:id',
    component: lazy(() => import('../Notification-details/index')),
    name: 'Detail Notification',
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'notification/edit/:id',
    component: lazy(() => import('../Notification-add/index')),
    name: 'Edit Notification',
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'doctor',
    roles: [],
    name: 'Doctors',
    component: lazy(() => import('../Doctor/index')),
  },
  {
    path: 'doctor/add',
    roles: [],
    name: 'Add',
    component: lazy(() => import('../Doctor-add/index')),
  },
  {
    path: 'doctor/details/:id',
    roles: [],
    name: 'Details',
    component: lazy(() => import('../Doctor-details/index')),
  },
  {
    path: 'doctor/edit/:id',
    roles: [],
    name: 'Edit',
    component: lazy(() => import('../Doctor-add/index')),
  },
  {
    path: 'admin-user',
    name: 'Admin Users',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../AdminUsers/index')),
  },
  {
    path: 'admin-user/details/:id',
    name: 'Details',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../AdminUsers-details/index')),
  },
  {
    path: 'admin-user/add',
    name: 'Add',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../AdminUsers-add/index')),
  },
  {
    path: 'admin-user/edit/:id',
    name: 'Edit',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../AdminUsers-add/index')),
  },
  {
    path: 'patients',
    name: 'Patients',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Patients/index')),
  },
  {
    path: 'patients/profile/:id',
    name: 'Profile',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Patients-details/profile')),
  },
  {
    path: 'patients/timeline/:id',
    name: 'Timeline',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Patients-timeline/index')),
  },
  {
    path: 'patients/view/:id',
    name: 'View',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Patients-view/index')),
  },
  {
    path: 'experience',
    name: 'Experiences',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Experience/index')),
  },
  {
    path: 'experience/add',
    name: 'Add',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Experience-add/index')),
  },
  {
    path: 'experience/details/:id',
    name: 'Details',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Experience-details/index')),
  },
  {
    path: 'experience/edit/:id',
    name: 'Edit',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Experience-add/index')),
  },
  {
    path: 'registerCouncil',
    roles: ['superadmin', 'admin'],
    name: 'Register Council',
    component: lazy(() => import('../RegisterCouncil/index')),
  },
  {
    path: 'registerCouncil/add',
    roles: ['superadmin', 'admin'],
    name: 'Add',
    component: lazy(() => import('../RegisterCouncil-add/index')),
  },
  {
    path: 'registerCouncil/details/:id',
    roles: ['superadmin', 'admin'],
    name: 'Details',
    component: lazy(() => import('../RegisterCouncil-details/index')),
  },
  {
    path: 'registerCouncil/edit/:id',
    name: 'Edit',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../RegisterCouncil-add/index')),
  },
  {
    path: 'followupPeriod',
    name: 'Followup Period',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../FollowupPeriod/index')),
  },
  {
    path: 'followupPeriod/add',
    name: 'Add',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../FollowupPeriod-add/index')),
  },
  {
    path: 'followupPeriod/details/:id',
    name: 'Details',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../FollowupPeriod-details/index')),
  },
  {
    path: 'followupPeriod/edit/:id',
    name: 'Edit',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../FollowupPeriod-add/index')),
  },
  {
    path: 'appointments/view/:id',
    name: 'View Appointments',
    exact: true,
    component: lazy(() => import('../Appointments-view/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'appointments/:status',
    name: 'Appointments',
    component: lazy(() => import('../Appointments/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'reviews',
    name: 'Reviews',
    component: lazy(() => import('../Reviews/index')),
    //roles: ["superadmin", "l3_admin"],
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'userProfile',
    name: 'Profile',
    component: lazy(() => import('../UserProfile/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'requestVerification/:status',
    name: 'Request Verification',
    component: lazy(() => import('../RequestVerification/index')),
    roles: ['superadmin'],
    exact: true,
  },
  {
    path: 'requestVerification/:status/view/:id',
    name: 'Request Verification Details',
    component: lazy(() => import('../RequestVerification-details/index')),
    roles: ['superadmin'],
    exact: true,
  },
  {
    path: 'payment/doctor/list',
    name: 'Payments',
    component: lazy(() => import('../DoctorsPayment/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'payment/:status',
    name: 'Payments',
    component: lazy(() => import('../Payment/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'state',
    name: 'States',
    component: lazy(() => import('../State/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'state/add',
    name: 'Add State',
    component: lazy(() => import('../State-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'state/edit/:id',
    name: 'Edit State',
    component: lazy(() => import('../State-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'state/details/:id',
    name: 'Edit State',
    component: lazy(() => import('../State-details/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'city',
    name: 'city',
    component: lazy(() => import('../City/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'city/add',
    name: 'Add city',
    component: lazy(() => import('../City-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'city/edit/:id',
    name: 'Edit city',
    component: lazy(() => import('../City-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'city/details/:id',
    name: 'Details city',
    component: lazy(() => import('../City-details/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'pincode',
    name: 'pincode',
    component: lazy(() => import('../Pincode/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'pincode/add',
    name: 'Add pincode',
    component: lazy(() => import('../Pincode-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'pincode/edit/:id',
    name: 'Edit pincode',
    component: lazy(() => import('../Pincode-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'pincode/details/:id',
    name: 'Details pincode',
    component: lazy(() => import('../Pincode-details/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'features',
    name: 'Features',
    component: lazy(() => import('../Slides/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'features/add',
    name: 'Add Features',
    component: lazy(() => import('../Slides-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'features/edit/:id',
    name: 'Edit Features',
    component: lazy(() => import('../Slides-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'features/details/:id',
    name: 'Details Features',
    component: lazy(() => import('../Slides-details/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'about',
    name: 'about',
    component: lazy(() => import('../About/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'about/add',
    name: 'Add about',
    component: lazy(() => import('../About-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'about/edit/:id',
    name: 'Edit about',
    component: lazy(() => import('../About-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'about/details/:id',
    name: 'Details about',
    component: lazy(() => import('../About-details/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'notifications/doctorInCompleteProfile',
    name: 'Doctor InComplete Profile',
    component: lazy(() => import('../Doctor-Incomplete/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'notifications/doctorClinicInCompleteProfile',
    name: 'Doctor Clinic In Complete',
    component: lazy(() => import('../Doctor-clinic-Incomplete/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'notifications/patientInCompleteProfile',
    name: 'Patient In Complete Profile',
    component: lazy(() => import('../Patient-Incomplete/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'notifications/doctorNotAddedPrescription',
    name: 'Prescription Not Added',
    component: lazy(() => import('../Prescription-Not-Added/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'notifications/patientNotAddedFamilyMember',
    name: 'Patient Family member Not Added',
    component: lazy(() => import('../Patient-Family-Not-Added/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'notifications/patientNotAddedInsuranceDetails',
    name: 'Patient Insurance Not Added',
    component: lazy(() => import('../Patient-Insurance-Not-Added/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'vision',
    name: 'vision',
    component: lazy(() => import('../Vision/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'vision/add',
    name: 'Add vision',
    component: lazy(() => import('../Vision-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'vision/edit/:id',
    name: 'Edit vision',
    component: lazy(() => import('../Vision-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'vision/details/:id',
    name: 'Details vision',
    component: lazy(() => import('../Vision-details/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'faq',
    name: 'faq',
    component: lazy(() => import('../Faq/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'faq/add',
    name: 'Add faq',
    component: lazy(() => import('../Faq-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'faq/edit/:id',
    name: 'Edit faq',
    component: lazy(() => import('../Faq-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'faq/details/:id',
    name: 'Details faq',
    component: lazy(() => import('../Faq-details/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'contactUs',
    name: 'Contact Us',
    component: lazy(() => import('../ContactUs/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'contactUs/add',
    name: 'Add Contact Us',
    component: lazy(() => import('../ContactUs-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'contactUs/edit/:id',
    name: 'Edit Contact Us',
    component: lazy(() => import('../ContactUs-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'contactUs/details/:id',
    name: 'Details Contact Us',
    component: lazy(() => import('../ContactUs-details/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'privacyPolicy',
    name: 'Privacy Policy',
    component: lazy(() => import('../PrivacyPolicy/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'privacyPolicy/add',
    name: 'Add Privacy Policy',
    component: lazy(() => import('../PrivacyPolicy-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'privacyPolicy/edit/:id',
    name: 'Edit Privacy Policy',
    component: lazy(() => import('../PrivacyPolicy-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'privacyPolicy/details/:id',
    name: 'Details Privacy Policy',
    component: lazy(() => import('../PrivacyPolicy-details/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'cancelRefundPolicy',
    name: 'Cancellation Refund Policy',
    component: lazy(() => import('../CancelRefundPolicy/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'cancelRefundPolicy/add',
    name: 'Add Cancellation Refund Policy',
    component: lazy(() => import('../CancelRefundPolicy-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'cancelRefundPolicy/edit/:id',
    name: 'Edit Cancellation Refund Policy',
    component: lazy(() => import('../CancelRefundPolicy-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'cancelRefundPolicy/details/:id',
    name: 'Cancellation Refund Policy Details',
    component: lazy(() => import('../CancelRefundPolicy-details/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'relationships',
    name: 'Relationship',
    component: lazy(() => import('../Relationship/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'relationships/add',
    name: 'Add Relationship',
    component: lazy(() => import('../Relationship-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'relationships/edit/:id',
    name: 'Edit Relationship',
    component: lazy(() => import('../Relationship-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'relationships/details/:id',
    name: 'Details Relationship',
    component: lazy(() => import('../Relationship-details/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'invoices',
    name: 'Invoices',
    component: lazy(() => import('../Invoice/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'invoices/details/:id',
    name: 'Invoice Details',
    exact: true,
    component: lazy(() => import('../Invoice-details/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'fmc',
    name: 'FMC',
    roles: ['superadmin', 'admin', 'fmc', 'doctor'],
    component: lazy(() => import('../Fmc/index')),
  },
  {
    path: 'fmc/dashboard',
    name: 'FMC Dashboard',
    roles: ['superadmin', 'fmc', 'doctor'],
    component: lazy(() => import('../Fmc-dashboard/index')),
  },
  {
    path: 'fmc/details/:id',
    name: 'Details',
    roles: ['superadmin', 'admin', 'fmc', 'doctor'],
    component: lazy(() => import('../Fmc-details/index')),
  },
  {
    path: 'fmc/view/:id',
    name: 'CC View',
    roles: ['superadmin', 'admin', 'fmc', 'doctor'],
    component: lazy(() => import('../Fmc-owner/index')),
  },
  {
    path: 'fmc/drpatient',
    name: 'FMC Doctor Patient',
    roles: ['superadmin', 'doctor'],
    component: lazy(() => import('../Fmc-doctor-patient/index')),
  },
  {
    path: 'fmc/medprescribed',
    name: 'FMC Medicine Prescribed',
    roles: ['superadmin'],
    component: lazy(() => import('../Fmc-medicine-prescribed/index')),
  },
  {
    path: 'fmc/fmclogs',
    name: 'FMC Logs',
    roles: ['superadmin'],
    component: lazy(() => import('../Fmc-Logs/index')),
  },
  {
    path: 'fmc/add',
    name: 'Add',
    roles: ['superadmin', 'fmc', 'doctor'],
    component: lazy(() => import('../Fmc-add/index')),
  },
  {
    path: 'fmc/edit/:id',
    name: 'Edit',
    roles: ['superadmin', 'fmc', 'doctor'],
    component: lazy(() => import('../Fmc-add/index')),
  },
  {
    path: 'fmc/tests',
    name: 'Test',
    roles: ['superadmin'],
    component: lazy(() => import('../Fmc-Tests/index')),
  },
  {
    path: 'fmc/tests/details/:id',
    name: 'Details',
    roles: ['superadmin'],
    component: lazy(() => import('../Fmc-Tests-Details/index')),
  },
  {
    path: 'fmc/slots',
    name: 'View',
    roles: ['superadmin', 'fmc', 'doctor'],
    component: lazy(() => import('../Fmc-slots/index')),
  },
  {
    path: 'fmc/slots/add',
    name: 'Add',
    roles: ['superadmin', 'fmc', 'doctor'],
    component: lazy(() => import('../Fmc-slotsAdd/index')),
  },
  {
    path: 'fmc/slots/edit/:id',
    name: 'Edit',
    roles: ['superadmin', 'fmc', 'doctor'],
    component: lazy(() => import('../Fmc-slotsAdd/index')),
  },
  {
    path: 'fmc/slots/details/:id',
    name: 'Details',
    roles: ['superadmin', 'fmc', 'doctor'],
    component: lazy(() => import('../Fmc-slotsDetail/index')),
  },
  {
    path: 'fmc/support/ticket',
    name: 'Support Ticket',
    roles: ['superadmin'],
    component: lazy(() => import('../Support-Test-Ticket/index')),
  },
  {
    path: 'fmc/support/ticket/edit/:id',
    name: 'Support Ticket Update',
    roles: ['superadmin'],
    component: lazy(() => import('../Support-Test-Ticket-Add/index')),
  },
  {
    path: 'fmc/support/ticket/details/:id',
    name: 'Support Ticket Details',
    roles: ['superadmin'],
    component: lazy(() => import('../Support-Test-Ticket-Details/index')),
  },
  {
    path: 'medicine',
    name: 'Medicine',
    roles: ['superadmin', 'railway'],
    component: lazy(() => import('../Medicine/index')),
  },
  {
    path: 'medicine/add',
    name: 'Add',
    roles: ['superadmin', 'railway'],
    component: lazy(() => import('../Medicine-Add/index')),
  },
  {
    path: 'medicine/details/:id',
    name: 'Details',
    roles: ['superadmin', 'railway'],
    component: lazy(() => import('../Medicine-Details/index')),
  },
  {
    path: 'medicine/edit/:id',
    name: 'Edit',
    roles: ['superadmin', 'railway'],
    component: lazy(() => import('../Medicine-Add/index')),
  },
  {
    path: 'atm',
    name: 'ATM Test',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Pathology-Test/index')),
  },
  {
    path: 'atm/add',
    name: 'Add',
    roles: ['superadmin'],
    component: lazy(() => import('../Pathology-Test-Add/index')),
  },
  {
    path: 'atm/edit/:id',
    name: 'Edit',
    roles: ['superadmin'],
    component: lazy(() => import('../Pathology-Test-Add/index')),
  },
  {
    path: 'atm/details/:id',
    name: 'Details',
    roles: ['superadmin'],
    component: lazy(() => import('../Pathology-Test-Detail/index')),
  },
  {
    path: 'atmmaster',
    name: 'ATM Test Master',
    roles: ['superadmin'],
    component: lazy(() => import('../Pathology-Test-Master/index')),
  },
  {
    path: 'atmmaster/add',
    name: 'Add',
    roles: ['superadmin'],
    component: lazy(() => import('../Pathology-Test-Master-Add/index')),
  },
  {
    path: 'atmmaster/edit/:id',
    name: 'Edit',
    roles: ['superadmin'],
    component: lazy(() => import('../Pathology-Test-Master-Add/index')),
  },
  {
    path: 'atmmaster/details/:id',
    name: 'Details',
    roles: ['superadmin'],
    component: lazy(() => import('../Pathology-Test-Master-Detail/index')),
  },
  {
    path: 'atmgroupmaster',
    name: 'ATM Test Group Master',
    roles: ['superadmin'],
    component: lazy(() => import('../Pathology-Test-Group-Master/index')),
  },
  {
    path: 'atmgroupmaster/add',
    name: 'Add',
    roles: ['superadmin'],
    component: lazy(() => import('../Pathology-Test-Group-Master-Add/index')),
  },
  {
    path: 'atmgroupmaster/edit/:id',
    name: 'Edit',
    roles: ['superadmin'],
    component: lazy(() => import('../Pathology-Test-Group-Master-Add/index')),
  },
  {
    path: 'atmgroupmaster/details/:id',
    name: 'Details',
    roles: ['superadmin'],
    component: lazy(() =>
      import('../Pathology-Test-Group-Master-Detail/index')
    ),
  },
  {
    path: 'atmgroup',
    name: 'ATM Test Group',
    roles: ['superadmin'],
    component: lazy(() => import('../Pathology-Test-Group/index')),
  },
  {
    path: 'atmgroup/add',
    name: 'Add',
    roles: ['superadmin'],
    component: lazy(() => import('../Pathology-Test-Group-Add/index')),
  },
  {
    path: 'atmgroup/edit/:id',
    name: 'Edit',
    roles: ['superadmin'],
    component: lazy(() => import('../Pathology-Test-Group-Add/index')),
  },
  {
    path: 'atmgroup/details/:id',
    name: 'Details',
    roles: ['superadmin'],
    component: lazy(() => import('../Pathology-Test-Group-Detail/index')),
  },
  {
    path: 'callibrationInventoryMaster',
    name: 'Callibration Inventory Master',
    roles: ['superadmin'],
    component: lazy(() => import('../Callibration-Inventory-Master/index')),
  },
  {
    path: 'callibrationInventoryMaster/add',
    name: 'Add',
    roles: ['superadmin'],
    component: lazy(() => import('../Callibration-Inventory-Master-Add/index')),
  },
  {
    path: 'callibrationInventoryMaster/edit/:id',
    name: 'Edit',
    roles: ['superadmin'],
    component: lazy(() => import('../Callibration-Inventory-Master-Add/index')),
  },
  {
    path: 'callibrationInventoryMaster/details/:id',
    name: 'Details',
    roles: ['superadmin'],
    component: lazy(() =>
      import('../Callibration-Inventory-Master-Details/index')
    ),
  },
  {
    path: 'callibrationInventory',
    name: 'Callibration Inventory',
    roles: ['superadmin'],
    component: lazy(() => import('../Callibration-Inventory/index')),
  },
  {
    path: 'callibrationInventory/add',
    name: 'Add',
    roles: ['superadmin'],
    component: lazy(() => import('../Callibration-Inventory-Add/index')),
  },
  {
    path: 'callibrationInventory/edit/:id',
    name: 'Edit',
    roles: ['superadmin'],
    component: lazy(() => import('../Callibration-Inventory-Add/index')),
  },
  {
    path: 'callibrationInventory/details/:id',
    name: 'Details',
    roles: ['superadmin'],
    component: lazy(() => import('../Callibration-Inventory-Details/index')),
  },
  {
    path: 'addonservices',
    name: 'Add On Sevices',
    roles: ['superadmin'],
    component: lazy(() => import('../Dressing-Charges/index')),
  },
  {
    path: 'addonservices/add',
    name: 'Add',
    roles: ['superadmin'],
    component: lazy(() => import('../Dressing-Charges-Add/index')),
  },
  {
    path: 'addonservices/edit/:id',
    name: 'Edit',
    roles: ['superadmin'],
    component: lazy(() => import('../Dressing-Charges-Add/index')),
  },
  {
    path: 'addonservices/details/:id',
    name: 'Details',
    roles: ['superadmin'],
    component: lazy(() => import('../Dressing-Charges-Detail/index')),
  },
  {
    path: 'addonservicesmaster',
    name: 'Add On Sevices Master',
    roles: ['superadmin'],
    component: lazy(() => import('../Dressing-Charges-Master/index')),
  },
  {
    path: 'addonservicesmaster/add',
    name: 'Add',
    roles: ['superadmin'],
    component: lazy(() => import('../Dressing-Charges-Master-Add/index')),
  },
  {
    path: 'addonservicesmaster/edit/:id',
    name: 'Edit',
    roles: ['superadmin'],
    component: lazy(() => import('../Dressing-Charges-Master-Add/index')),
  },
  {
    path: 'addonservicesmaster/details/:id',
    name: 'Details',
    roles: ['superadmin'],
    component: lazy(() => import('../Dressing-Charges-Master-Detail/index')),
  },
  {
    path: 'department',
    name: 'Departments',
    component: lazy(() => import('../Department/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'department/add',
    name: 'Add Department',
    component: lazy(() => import('../Department-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'department/edit/:id',
    name: 'Edit Department',
    component: lazy(() => import('../Department-add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'department/details/:id',
    name: 'Department Details',
    component: lazy(() => import('../Department-details/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'atmterms',
    name: 'Atm Terms',
    component: lazy(() => import('../Atm-Terms/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'atmterms/add',
    name: 'Add Atm terms',
    component: lazy(() => import('../Atm-Terms-Add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'atmterms/edit/:id',
    name: 'Edit Atm terms',
    component: lazy(() => import('../Atm-Terms-Add/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'atmterms/details/:id',
    name: 'Atm terms Details',
    component: lazy(() => import('../Atm-Terms-Details/index')),
    roles: ['superadmin', 'admin'],
  },
  {
    path: 'g4sfmc',
    name: 'G4S Fmc',
    roles: ['superadmin'],
    component: lazy(() => import('../G4S-Fmc/index')),
  },
  {
    path: 'g4sfmc/add',
    name: 'Add',
    roles: ['superadmin'],
    component: lazy(() => import('../G4S-Fmc-add/index')),
  },
  {
    path: 'g4sfmc/edit/:id',
    name: 'Edit',
    roles: ['superadmin'],
    component: lazy(() => import('../G4S-Fmc-add/index')),
  },
  {
    path: 'g4sfmc/details/:id',
    name: 'Details',
    roles: ['superadmin'],
    component: lazy(() => import('../G4S-Fmc-details/index')),
  },
  {
    path: 'atmdevice',
    name: 'Atm Device',
    component: lazy(() => import('../Pathology-Test-Device/index')),
    roles: ['superadmin'],
  },
  {
    path: 'atmdevice/add',
    name: 'Add Atm Device',
    component: lazy(() => import('../Pathology-Test-Device-Add/index')),
    roles: ['superadmin'],
  },
  {
    path: 'atmdevice/edit/:id',
    name: 'Edit Atm Device',
    component: lazy(() => import('../Pathology-Test-Device-Add/index')),
    roles: ['superadmin'],
  },
  {
    path: 'atmdevice/details/:id',
    name: 'Atm Device Details',
    component: lazy(() => import('../Pathology-Test-Device-Details/index')),
    roles: ['superadmin'],
  },
  {
    path: 'dietsheet',
    name: 'Diet Sheet',
    component: lazy(() => import('../DietSheet/index')),
    roles: ['superadmin'],
  },
  {
    path: 'dietsheet/add',
    name: 'Add Diet Sheet',
    component: lazy(() => import('../DietSheet-add/index')),
    roles: ['superadmin'],
  },
  {
    path: 'dietsheet/edit/:id',
    name: 'Edit Diet Sheet',
    component: lazy(() => import('../DietSheet-add/index')),
    roles: ['superadmin'],
  },
  {
    path: 'dietsheet/details/:id',
    name: 'Diet Sheet Details',
    component: lazy(() => import('../DietSheet-details/index')),
    roles: ['superadmin'],
  },
  {
    path: 'rangemaster',
    name: 'Range Master',
    component: lazy(() => import('../Range-Master/index')),
    roles: ['superadmin'],
  },
  {
    path: 'rangemaster/add',
    name: 'Add Range Master',
    component: lazy(() => import('../Range-Master-Add/index')),
    roles: ['superadmin'],
  },
  {
    path: 'rangemaster/edit/:id',
    name: 'Edit Range Master',
    component: lazy(() => import('../Range-Master-Add/index')),
    roles: ['superadmin'],
  },
  {
    path: 'rangemaster/details/:id',
    name: 'Range Master Details',
    component: lazy(() => import('../Range-Master-Detail/index')),
    roles: ['superadmin'],
  },
  {
    path: 'simirapin',
    name: 'Simira Pincode',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../SimiraPincode/index')),
  },
  {
    path: 'simirapin/add',
    name: 'Add',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../SimiraPincode-Add/index')),
  },
  {
    path: 'simirapin/details/:id',
    name: 'Details',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../SimiraPincode-Details/index')),
  },
  {
    path: 'simirapin/edit/:id',
    name: 'Edit',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../SimiraPincode-Add/index')),
  },
  {
    path: 'educational_categorymaster',
    name: 'Educational Category Master',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Category-Master/index')),
  },
  {
    path: 'educational_categorymaster/add',
    name: 'Add',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Category-Master-Add/index')),
  },
  {
    path: 'educational_categorymaster/details/:id',
    name: 'Details',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Category-Master-Details/index')),
  },
  {
    path: 'educational_categorymaster/edit/:id',
    name: 'Edit',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Category-Master-Add/index')),
  },
  {
    path: 'educational_video',
    name: 'Educational Video',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Video/index')),
  },
  {
    path: 'educational_video/add',
    name: 'Add',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Video-add/index')),
  },
  {
    path: 'educational_video/details/:id',
    name: 'Details',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Video-details/index')),
  },
  {
    path: 'educational_video/edit/:id',
    name: 'Edit',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Video-add/index')),
  },
  {
    path: 'ecgkey',
    name: 'Ecg Key',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../ScichartKey/index')),
  },
  {
    path: 'ecgkey/add',
    name: 'Add',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../ScichartKey-add/index')),
  },
  {
    path: 'ecgkey/details/:id',
    name: 'Details',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../ScichartKey-details/index')),
  },
  {
    path: 'ecgkey/edit/:id',
    name: 'Edit',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../ScichartKey-add/index')),
  },
  {
    path: 'specialitys',
    name: 'specialitys',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Specialitys/index')),
  },
  {
    path: 'specialitys/add',
    name: 'specialitys add',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Specialitys-add/index')),
  },
  {
    path: 'specialitys/details/:id',
    name: 'specialitys details',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Specialitys-details/index')),
  },
  {
    path: 'specialitys/edit/:id',
    name: 'specialitys edit',
    roles: ['superadmin', 'admin'],
    component: lazy(() => import('../Specialitys-add/index')),
  },
  {
    path: 'udctest',
    name: 'Udc Test',
    roles: ['superadmin', 'admin','railway'],
    component: lazy(() => import('../UdcTest/index')),
  },
  {
    path: 'udctest/add',
    name: 'Add',
    roles: ['superadmin', 'admin','railway'],
    component: lazy(() => import('../UdcTest-Add/index')),
  },
  {
    path: 'udctest/edit/:id',
    name: 'Edit',
    roles: ['superadmin', 'admin','railway'],
    component: lazy(() => import('../UdcTest-Add/index')),
  },
  {
    path: 'udctest/details/:id',
    name: 'Details',
    roles: ['superadmin', 'admin','railway'],
    component: lazy(() => import('../UdcTest-Details/index')),
  },
  {
    path: 'imglocation',
    name: 'Imaging Test Location',
    roles: ['superadmin', 'admin','railway'],
    component: lazy(() => import('../ImagingTestLocationMaster/index')),
  },
  {
    path: 'imglocation/add',
    name: 'Add',
    roles: ['superadmin', 'admin','railway'],
    component: lazy(() => import('../ImagingTestLocationMaster-Add/index')),
  },
  {
    path: 'imglocation/edit/:id',
    name: 'Edit',
    roles: ['superadmin', 'admin','railway'],
    component: lazy(() => import('../ImagingTestLocationMaster-Add/index')),
  },
  {
    path: 'imglocation/details/:id',
    name: 'Details',
    roles: ['superadmin', 'admin','railway'],
    component: lazy(() => import('../ImagingTestLocationMaster-Details/index')),
  },
  {
    path: 'imgtesttype',
    name: 'Imaging Test Type',
    roles: ['superadmin', 'admin','railway'],
    component: lazy(() => import('../ImagingTestTypeMaster/index')),
  },
  {
    path: 'imgtesttype/add',
    name: 'Add',
    roles: ['superadmin', 'admin','railway'],
    component: lazy(() => import('../ImagingTestTypeMaster-Add/index')),
  },
  {
    path: 'imgtesttype/edit/:id',
    name: 'Edit',
    roles: ['superadmin', 'admin','railway'],
    component: lazy(() => import('../ImagingTestTypeMaster-Add/index')),
  },
  {
    path: 'imgtesttype/details/:id',
    name: 'Details',
    roles: ['superadmin', 'admin','railway'],
    component: lazy(() => import('../ImagingTestTypeMaster-Details/index')),
  },
  
  {
    path: 'imagingmaster',
    name: 'Imaging Test',
    roles: ['superadmin', 'admin','railway'],
    component: lazy(() => import('../ImagingTestMaster/index')),
  },
  {
    path: 'imagingmaster/add',
    name: 'Add',
    roles: ['superadmin', 'admin','railway'],
    component: lazy(() => import('../ImagingTestMaster-Add/index')),
  },
  {
    path: 'imagingmaster/edit/:id',
    name: 'Edit',
    roles: ['superadmin', 'admin','railway'],
    component: lazy(() => import('../ImagingTestMaster-Add/index')),
  },
  {
    path: 'imagingmaster/details/:id',
    name: 'Details',
    roles: ['superadmin', 'admin','railway'],
    component: lazy(() => import('../ImagingTestMaster-Details/index')),
  },
   //card
   {
    path: 'membershipcard',
    name: 'Membership Card',
    roles: [],
    component: lazy(() => import('../MembershipCard/index')),
  },
  {
    path: 'membershipcard/add',
    name: 'Add',
    roles: [],
    component: lazy(() => import('../MembershipCard-Add/index')),
  },
  {
    path: 'membershipcard/edit/:id',
    name: 'Edit',
    roles: [],
    component: lazy(() => import('../MembershipCard-Add/index')),
  },
  {
    path: 'membershipcard/details/:id',
    name: 'Details',
    roles: [],
    component: lazy(() => import('../MembershipCard-Details/index')),
  },
  {
    path: 'dentisttest',
    name: 'Dentist Test',
    roles: [],
    component: lazy(() => import('../DentistTest/index')),
  },
  {
    path: 'dentisttest/add',
    name: 'Add',
    roles: [],
    component: lazy(() => import('../DentistTest-Add/index')),
  },
  {
    path: 'dentisttest/edit/:id',
    name: 'Edit',
    roles: [],
    component: lazy(() => import('../DentistTest-Add/index')),
  },
  {
    path: 'dentisttest/details/:id',
    name: 'Details',
    roles: [],
    component: lazy(() => import('../DentistTest-Details/index')),
  },
  {
    path: 'opthlmotest',
    name: 'Opthlmo Test',
    roles: [],
    component: lazy(() => import('../OpthlmoTest/index')),
  },
  {
    path: 'opthlmotest/add',
    name: 'Add',
    roles: [],
    component: lazy(() => import('../OpthlmoTest-Add/index')),
  },
  {
    path: 'opthlmotest/edit/:id',
    name: 'Edit',
    roles: [],
    component: lazy(() => import('../OpthlmoTest-Add/index')),
  },
  {
    path: 'opthlmotest/details/:id',
    name: 'Details',
    roles: [],
    component: lazy(() => import('../OpthlmoTest-Details/index')),
  },
  {
    path: '*',
    name: 'not found',
    component: lazy(() => import('../Page/404/index')),
    roles: ['superadmin', 'admin'],
  },
];

class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: ['superadmin', 'admin', 'doctor'],
    };
  }

  render() {
    const { url, style } = this.props;
    return (
      <Suspense fallback={<Loader />}>
        <div style={style}>
          <Switch>
            {routes.map((singleRoute) => {
              const { path, exact, roles, ...otherProps } = singleRoute;
              let flag = false;
              if (
                typeof roles !== 'undefined' &&
                (roles.length === 0 || roles.includes(this.props.role_name))
              ) {
                flag = true;
              } else {
                flag = false;
              }

              return flag ? (
                <Route
                  exact={exact === false ? false : true}
                  key={singleRoute.path}
                  path={`${url}/${singleRoute.path}`}
                  {...otherProps}
                />
              ) : null;
            })}
          </Switch>
        </div>
      </Suspense>
    );
  }
}

export default connect(
  (state) => ({
    role_name: state.Auth.role_name,
  }),
  {}
)(AppRouter);
