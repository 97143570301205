import { getDefaultPath } from '../../helpers/urlSync';

const options = [
  {
    label: 'sidebar.master',
    key: 'master',
    leftIcon: 'dashboard',
    roles: ['superadmin', 'admin'],
    children: [
      {
        label: 'sidebar.speciality',
        key: 'speciality',
        roles: [],
      },
      {
        label: 'Specialitys',
        key: 'specialitys',
        roles: [],
      },
      {
        label: 'sidebar.RegistrationCouncil',
        key: 'registerCouncil',
        roles: [],
      },
      {
        label: 'sidebar.experience',
        key: 'experience',
        roles: [],
      },
      {
        label: 'Educational Category Master',
        key: 'educational_categorymaster',
        roles: [],
      },
      {
        label: 'Educational Videos',
        key: 'educational_video',
        roles: [],
      },
      {
        label: 'Simira Pincode',
        key: 'simirapin',
        roles: [],
      },
      {
        label: 'sidebar.followupPeriod',
        key: 'followupPeriod',
        roles: [],
      },
      // {
      //   label: "sidebar.ExperienceRange",
      // roles: []

      //   key: "experience-range"
      // },
      {
        label: 'sidebar.languages',
        key: 'languages',
        roles: [],
      },
      {
        label: 'sidebar.relationships',
        key: 'relationships',
        roles: [],
      },
      {
        label: 'Department',
        key: 'department',
        roles: [],
      },
      // {
      //   label: "sidebar.notification",
      // roles: []

      //   key: "notification"
      // },
      {
        label: 'sidebar.faq',
        key: 'faq',
        roles: [],
      },
      {
        label: 'sidebar.contactUs',
        key: 'ContactUs',
        roles: [],
      },
    ],
  },
  {
    label: 'sidebar.doctor',
    key: 'doctor',
    leftIcon: 'person',
    roles: ['superadmin', 'admin', 'doctor'],
  },
  {
    label: 'sidebar.patient',
    key: 'patient',
    leftIcon: 'people',
    roles: ['superadmin', 'admin'],
    children: [
      {
        label: 'sidebar.patientsList',
        key: 'patients',
        roles: [],
      },
      // {
      //   label: "sidebar.patientHistory",
      //   key: "patientsHistory",
      //   roles: ["superadmin", "l3_admin"],
      // },
      // {
      //   label: "sidebar.appointmentTimeline",
      //   key: "appointmentTimeline",
      //   roles: ["superadmin", "l3_admin"],
      // },
      // {
      //   label: 'sidebar.billings',
      //   key: 'billings',
      //   roles: ['superadmin', 'l3_admin'],
      // },
    ],
  },
  {
    label: 'sidebar.appointment',
    leftIcon: 'event',
    key: 'appointments',
    roles: ['superadmin', 'admin'],
    children: [
      {
        label: 'sidebar.Appointments',
        key: 'appointments/All',
        roles: [],
      },
      {
        label: 'sidebar.completeAppointments',
        key: 'appointments/Completed',
        roles: [],
      },
      {
        label: 'sidebar.missedAppointments',
        key: 'appointments/Missed',
        roles: [],
      },
      {
        label: 'sidebar.confirmAppointments',
        key: 'appointments/Confirmed',
        roles: [],
      },
      {
        label: 'sidebar.cancelledAppointments',
        key: 'appointments/Cancelled',
        roles: [],
      },
      {
        label: 'sidebar.reschduledAppointments',
        key: 'appointments/Rescheduled',
        roles: [],
      },
    ],
  },
  {
    label: 'sidebar.fmc',
    leftIcon: 'local_hospital',
    key: 'fmc',
    roles: ['superadmin', 'admin', 'fmc', 'doctor'],
    children: [
      {
        label: 'sidebar.fmcDashboard',
        key: 'fmc/dashboard',
        roles: ['superadmin', 'fmc', 'doctor'],
      },
      {
        label: 'FMC Doctor Patient',
        key: 'fmc/drpatient',
        roles: ['superadmin', 'doctor'],
      },
      {
        label: 'FMC Medicine Prescribed',
        key: 'fmc/medprescribed',
        roles: ['superadmin'],
      },
      {
        label: 'G4S Fmc',
        key: 'g4sfmc',
        roles: ['superadmin'],
      },
      // {
      //   label: 'FMC Logs',
      //   key: 'fmc/fmclogs',
      //   roles: ['superadmin'],
      // },
      {
        label: 'sidebar.fmcAddView',
        key: 'fmc',
        roles: ['superadmin', 'admin', 'fmc', 'doctor'],
      },
      {
        label: 'sidebar.fmcSlotsAddRemove',
        key: 'fmc/slots',
        roles: ['superadmin', 'fmc', 'doctor'],
      },
      {
        label: 'FMC Tests',
        key: 'fmc/tests',
        roles: ['superadmin'],
      },
      {
        label: 'Add/Update Diet Sheet',
        key: 'dietsheet',
        roles: ['superadmin'],
      },
      {
        label: 'Callibration Inventory',
        key: 'callibrationInventory',
        roles: ['superadmin'],
      },
      {
        label: 'sidebar.viewSupportTicket',
        key: 'fmc/support/ticket',
        roles: ['superadmin'],
      },
    ],
  },
  {
    label: 'Add On Services',
    leftIcon: 'vaccines',
    key: 'addonservices',
    roles: ['superadmin', 'admin'],
    children: [
      {
        label: 'Add On Services Master',
        key: 'addonservicesmaster',
        roles: ['superadmin'],
      },
      {
        label: 'Clinic Wise Add on Services',
        key: 'addonservices',
        roles: ['superadmin'],
      },
    ],
  },
  {
    label: 'Medicine',
    leftIcon: 'medication',
    key: 'medicine',
    roles: ['superadmin', 'admin','railway'],
  },
  {
    label: 'ATM Test',
    leftIcon: 'science',
    key: 'atm',
    roles: ['superadmin', 'admin'],
    children: [
      {
        label: 'ATM Test Master',
        key: 'atmmaster',
        roles: [],
      },
      {
        label: 'Add/Remove Test',
        key: 'atm',
        roles: [],
      },
      {
        label: 'ATM Test Group Master',
        key: 'atmgroupmaster',
        roles: ['superadmin'],
      },
      {
        label: 'ATM Test Group Discount',
        key: 'atmgroup',
        roles: ['superadmin'],
      },
      {
        label: 'Atm Device Master',
        key: 'atmdevice',
        roles: ['superadmin'],
      },
      {
        label: 'Range Master',
        key: 'rangemaster',
        roles: ['superadmin'],
      },
      {
        label: 'Callibration Inventory Master',
        key: 'callibrationInventoryMaster',
        roles: ['superadmin'],
      },
      {
        label: 'Ecg Key',
        key: 'ecgkey',
        roles: ['superadmin'],
      },
    ],
  },
  {
    label: 'Imaging Test',
    leftIcon: 'science',
    key: 'imaging',
    roles: ['superadmin', 'admin','railway'],
    children: [
      {
        label: 'Imaging Test Master',
        key: 'imagingmaster',
        roles: ['superadmin', 'admin','railway'],
      },
      {
        label: 'Location Master',
        key: 'imglocation',
        roles: ['superadmin', 'admin','railway'],
      },
      {
        label: 'Test Type Master',
        key: 'imgtesttype',
        roles: ['superadmin', 'admin','railway'],
      },
      // {
      //   label: 'Lab Master',
      //   key: 'atmgroup',
      //   roles: ['superadmin'],
      // }
    ],
  },
  {
    label: 'UDC Test',
    leftIcon: 'science',
    key: 'udctest',
    roles: ['superadmin', 'admin','railway'],
  },
  {
    label: 'Dentist Test',
    leftIcon: 'science',
    key: 'dentisttest',
    roles: ['superadmin', 'admin','railway'],
  },
  {
    label: 'Opthlmo Test',
    leftIcon: 'science',
    key: 'opthlmotest',
    roles: ['superadmin', 'admin','railway'],
  },
  {
    label: 'Membership card',
    leftIcon: 'science',
    key: 'membershipcard',
    roles: ['superadmin', 'admin',],
  },
  {
    label: 'sidebar.invoices',
    leftIcon: 'description',
    key: 'invoices',
    roles: ['superadmin', 'admin'],
  },
  {
    label: 'sidebar.payments',
    leftIcon: 'payment',
    key: 'payments',
    roles: ['superadmin', 'admin'],
    children: [
      {
        label: 'sidebar.viewPayments',
        key: 'payment/recent',
        roles: [],
      },
      // {
      //   label: "sidebar.makePayments",
      //   key: "payment/doctor/list",
      //   roles: [],
      // },
      {
        label: 'sidebar.completeTransactions',
        key: 'payment/Completed',
        roles: [],
      },
      // {
      //   label: "sidebar.rejectedTransactions",
      //   key: "payment/Rejected",
      //   roles: [],
      // },
      // {
      //   label: "sidebar.cancelledTransactions",
      //   key: "payment/Cancelled",
      //   roles: [],
      // },
      {
        label: 'sidebar.pendingTransactions',
        key: 'payment/Pending',
        roles: [],
      },
    ],
  },
  {
    label: 'sidebar.notifications',
    key: 'notifications',
    leftIcon: 'forum',
    roles: ['superadmin', 'admin'],
    children: [
      {
        label: 'sidebar.doctorNotAddedPrescription',
        key: 'notifications/doctorNotAddedPrescription',
        roles: [],
      },
      {
        label: 'sidebar.doctorInCompleteProfile',
        key: 'notifications/doctorInCompleteProfile',
        roles: [],
      },
      {
        label: 'sidebar.doctorClinicInCompleteProfile',
        key: 'notifications/doctorClinicInCompleteProfile',
        roles: [],
      },
      {
        label: 'sidebar.patientInCompleteProfile',
        key: 'notifications/patientInCompleteProfile',
        roles: [],
      },
      {
        label: 'sidebar.patientNotAddedFamilyMember',
        key: 'notifications/patientNotAddedFamilyMember',
        roles: [],
      },
      {
        label: 'sidebar.patientNotAddedInsuranceDetails',
        key: 'notifications/patientNotAddedInsuranceDetails',
        roles: [],
      },
    ],
  },
  {
    label: 'sidebar.content',
    key: 'content',
    leftIcon: 'receipt',
    roles: ['superadmin', 'admin'],
    children: [
      // {
      //   label: "sidebar.contentsList",
      //   key: "contents",
      //   roles: [],
      // },
      // {
      //   label: "sidebar.sectionsList",
      //   key: "sections",
      //   roles: [],
      // },
      {
        label: 'sidebar.features',
        key: 'features',
        roles: [],
      },
      {
        label: 'sidebar.about',
        key: 'about',
        roles: [],
      },
      {
        label: 'sidebar.vision',
        key: 'vision',
        roles: [],
      },
      {
        label: 'sidebar.privacyPolicy',
        key: 'PrivacyPolicy',
        roles: [],
      },
      {
        label: 'sidebar.cancelRefundPolicy',
        key: 'CancelRefundPolicy',
        roles: [],
      },
      {
        label: 'Atm terms',
        key: 'atmterms',
        roles: ['superadmin'],
      },
    ],
  },
  {
    label: 'sidebar.roles',
    leftIcon: 'tune',
    key: 'roles',
    roles: ['superadmin', 'admin'],
  },
  {
    label: 'sidebar.adminUser',
    leftIcon: 'account_box',
    key: 'admin-user',
    roles: ['superadmin', 'admin'],
  },
  {
    label: 'sidebar.reviews',
    leftIcon: 'star',
    key: 'reviews',
    // roles: ['superadmin', 'l3_admin']
    roles: ['superadmin', 'admin'],
  },
  {
    label: 'sidebar.requestVerification',
    leftIcon: 'verified_user',
    key: 'requestVerification',
    roles: ['superadmin'],
    children: [
      {
        label: 'sidebar.pendingVerification',
        key: 'requestVerification/pending',
        roles: ['superadmin'],
      },
      {
        label: 'sidebar.requestedVerification',
        key: 'requestVerification/requested',
        roles: ['superadmin'],
      },
      {
        label: 'sidebar.verifiedVerification',
        key: 'requestVerification/verified',
        roles: ['superadmin'],
      },
    ],
  },
  {
    label: 'sidebar.location',
    leftIcon: 'location_on',
    key: 'location',
    roles: ['superadmin', 'admin'],
    children: [
      {
        label: 'sidebar.state',
        key: 'state',
        roles: [],
      },
      {
        label: 'sidebar.city',
        key: 'city',
        roles: [],
      },
      {
        label: 'sidebar.pincode',
        key: 'pincode',
        roles: [],
      },
    ],
  },
];
const getBreadcrumbOption = () => {
  const preKeys = getDefaultPath();
  let parent, activeChildren;
  options.forEach((option) => {
    if (preKeys[option.key]) {
      parent = option;
      (option.children || []).forEach((child) => {
        if (preKeys[child.key]) {
          activeChildren = child;
        }
      });
    }
  });
  return { parent, activeChildren };
};
export default options;
export { getBreadcrumbOption };
