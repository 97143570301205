import axois from 'axios';

// User Login 
export const login = async (payload) =>
{
    let data = await axois.post('/api/adminUser/login', payload);
    return data;
}

export const getDetails = async (id) =>
{
  let data = await axois.get(`/api/adminUser/${id}`);
  return data;
}

export const getAuth = async () =>
{
  let data = await axois.get(`/api/adminUser/validate/authToken`);
  return data;
}