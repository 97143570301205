import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { IntlProvider } from 'react-intl';
import AppRouter from './appRouter';
import Sidebar from '../Sidebar';
import Topbar from '../Topbar';
import AppLocale from '../../languageProvider';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import themeActions from '../../redux/themeSwitcher/actions';
// import PageBreadcrumb from '../PageBreadcrumb';
// import Breadcrumbs from '../Breadcrumb';
import MUIPProvider from '../../components/uielements/materialUiPicker/momentProvider';
import { rtl } from '../../settings/withDirection';
import Main, { Root, AppFrame } from './style';
import axios from 'axios';
import './global.css';
// import {
//   useLocation,
//   useRouteMatch,} from "react-router-dom";
import { withRouter } from "react-router";

const { logout } = authAction;
const { toggleAll } = appActions;
const { switchActivation } = themeActions;

class App extends Component
{

  componentDidMount() {
    this.saveToken();
  }

  addFcmToken = async (dataObj, userId) =>
  {
    let data = await axios.post('/api/fcmToken/'+ userId, dataObj);
    return data.data;
  }


  saveToken = async (token) => {

    const { userProfile } = this.props;
    
    if(userProfile && userProfile.user_id !== undefined && userProfile.user_id !== null) {

      const healthNovoTempFcmToken = await localStorage.getItem('healthNovoTempFcmToken');
      const fcmToken = JSON.parse(await localStorage.getItem('healthNovoFcmToken'));
      
      if(fcmToken === null || fcmToken.device_token !== healthNovoTempFcmToken) {
        var data = {
          "userId": userProfile.user_id,
          "userType": 'admin',
          'device_token': healthNovoTempFcmToken,
          'device_type': 'web'
        };

        let fcmTokenResponse = await this.addFcmToken({token: data}, userProfile.user_id );
        if (fcmTokenResponse.fcmToken.token) {
          var value = JSON.stringify(data);
          await localStorage.setItem('healthNovoFcmToken', value);
        } else {
          console.log('Error Saving Token');
        }

      } 

    }

  }


  render() {
    const anchor = rtl === 'rtl' ? 'right' : 'left';
    const {
      classes,
      theme,
      toggleAll,
      locale,
      match,
      scrollHeight,
      fixedNavbar,
      view,
    } = this.props;
    const { url } = match;
    // const propsTopbar = { locale, url };
    const options = { url, classes, theme, locale };
    const currentAppLocale = AppLocale[locale];




    return (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Root>
          <Debounce time="1000" handler="onResize">
            <WindowResizeListener
              onResize={windowSize =>
                toggleAll(windowSize.windowWidth, windowSize.windowHeight)
              }
            />
          </Debounce>
          <AppFrame>
            <Topbar {...options} />
            {anchor === 'left' ? <Sidebar {...options} anchor={anchor} /> : ''}

            <Main
              className={
                view !== 'TabLandView' && view !== 'DesktopView'
                  ? ''
                  : fixedNavbar
                  ? 'fixedNav'
                  : 'notFixed'
              }
            >
              {/* <Breadcrumbs url={url} /> */}

              <MUIPProvider>
                <AppRouter
                  style={{ height: scrollHeight, overflowY: 'auto' }}
                  url={url}
                />
              </MUIPProvider>
              {/* <ThemeSwitcherButton /> */}
              {/* <SecondarySidebar
                InnerComponent={ThemeSwitcher}
                currentActiveKey="themeSwitcher"
                {...propsTopbar}
              /> */}
            </Main>

            {anchor === 'right' ? <Sidebar {...options} anchor={anchor} /> : ''}
          </AppFrame>
        </Root>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.Auth,
    locale: state.LanguageSwitcher.language.locale,
    scrollHeight: state.App.scrollHeight, // toJs()
    fixedNavbar: state.App.fixedNavbar,
    view: state.App.view,
    userProfile: state.Auth,
  };
};
const appConect = connect(
  mapStateToProps,
  {
    logout,
    toggleAll,
    switchActivation,
  }
)(App);
export default withRouter(appConect);